.events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }

  .cell {
    color: #ed473a;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    padding: 0 4px;
    line-height: 1.8;
  }
  
  .cell:hover {
    background-color: #efefef;
  }
  
  .ant-picker-cell:hover .ant-picker-calendar-date {
    background-color: white;
  }
  .cell {
    color: #ed473a;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    padding: 0 4px;
    line-height: 1.8;
  }
  
  