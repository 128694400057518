.long-text {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ffffff;
}

.long-text .ant-popover-inner,
.project-popover-node-type .ant-popover-inner {
  background: transparent;
  padding: 5px 10px;
}

.long-text .ant-popover-inner .ant-popover-title {
  margin-bottom: 0;
}

.actions-button .ant-popover-inner {
  height: 170px;
  width: 140px;
  border-radius: 50%;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
  transform: rotate(270deg);
  /* clip-path: insert(0 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%); */
}
